<template>
	<div>
		<b-container fluid class="shadow mb-5">
			<h5 class="text-center my-4" style="min-height: 1.2em">
				<a
					:href="url.text"
					class="text-decoration-none text-muted"
					target="_blank"
				>
					{{ url.text }}</a
				>
			</h5>

			<b-row class="mb-5">
				<b-col class="mb-5">
					<b-input-group class="mb=5" label="'Bad' Tags" label-for="badTags">
						<b-form-textarea
							class="mb-3"
							style="box-shadow: none"
							id="badTags"
							v-model="badTagsString"
							placeholder="Enter 'Bad' Tags (comma separated) ..."
							rows="1"
							max-rows="1"
							size="sm"
							squared
							trim
							description="text to analyze"
							title="Enter 'Bad' Tags (comma separated)"
							:state="badTags.length > 5"
							no-auto-shrink
						></b-form-textarea>
					</b-input-group>
					<b-input-group>
						<b-form-input
							id="url-string"
							style="box-shadow: none"
							v-model="url.text"
							placeholder="Enter url ..."
							size="sm"
							squared
							trim
							description="url"
							autofocus
							type="text"
							@keydown.enter.native="runUrl()"
						></b-form-input>

						<b-button-group>
							<b-dropdown
								variant="dark"
								size="sm"
								squared
								:text="
									'location: ' +
									url.location.name +
									' ' +
									'(' +
									url.location.code +
									')'
								"
								id="url-location"
								name="url.location"
								v-model="url.location"
							>
								<b-dropdown-item-button
									v-for="location in locations"
									:key="location.name"
									:value="location.country_iso_code"
									squared
									@click="keyword.location = location"
								>
									{{
										location.name + " " + "(" + location.country_iso_code + ")"
									}}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="url.language_code"
								:text="'language: ' + url.language_code"
							>
								<b-dropdown-item-button
									v-for="language_code in languages"
									:key="language_code"
									:value="language_code"
									squared
									@click="url.language_code = language_code"
								>
									{{ language_code }}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="url.device"
								:text="'device: ' + url.device"
							>
								<b-dropdown-item-button
									v-for="device in devices"
									:key="device"
									:value="device"
									squared
									@click="keyword.device = device"
								>
									{{ device }}
								</b-dropdown-item-button>
							</b-dropdown>
						</b-button-group>
						<b-button
							:pressed="false"
							style="box-shadow: none"
							size="sm"
							squared
							variant="danger"
							@click="runUrl()"
							>Analyze</b-button
						>
					</b-input-group>
				</b-col>
			</b-row>

			<!-- spinner -->
			<div class="text-center mb-5" v-if="isBusy && spinner">
				<b-row type="grow" class="justify-content-md-center my-5 pb-5">
					<b-spinner
						variant="dark"
						style="width: 25rem; height: 25rem"
					></b-spinner>
				</b-row>
				<span class="sr-only">Analyzing URL: "{{ url.text }}"</span>
			</div>
		</b-container>

		<b-container fluid v-if="!isBusy" class="shadow mt-5">
			<b-table
				id="concepts-table"
				:busy.sync="isBusy"
				:items="items"
				:fields="columns"
				ref="conceptsTable"
				stacked="md"
				show-empty
				small
				caption-top
			>
				<template #table-caption>Details</template>
			</b-table>
		</b-container>
	</div>
</template>

<script>
import { TaxonomyService } from "../services/taxonomy/taxonomy.service";

export default {
	name: "SinglePageUrl",
	components: {
		// FilterInput,
		// Chart,
	},
	data() {
		return {
			isBusy: true,
			spinner: false,

			badTags: [
				"header",
				"nav",
				"footer",
				"script",
				"noscript",
				"style",
				"video",
				"audio",
				"aside",
				"map",
				"optgroup",
				"object",
				"progress",
				"select",
				"svg",
				"figure",
				"iframe",
			],
			badTagsString: "",
			url: {
				text: "https://www.galileo.tv/food/schoko-kuchen-aus-nur-drei-zutaten-rezept-back-tipps-schokolade/",
				language_code: "de",
				device: "mobile",
				// location: ["2276", "Germany", "", "DE", "Country"],
				location: {
					code: 2276,
					name: "Germany",
					code_parent: "",
					country_iso_code: "DE",
					type: "Country",
				},

				forceUpdate: false,
			},
			languages: [
				"en",
				"de",
				"fa",
				"nl",
				"fr",
				"id",
				"it",
				// 'ja',
				"no",
				"pt",
				"ru",
				"es",
				"sv",
			],
			devices: ["desktop", "mobile"],
			locations: [],

			items: [],
			columns: [],
		};
	},

	computed: {},

	async beforeMount() {
		const locations = await TaxonomyService.getLocations();
		this.locations = locations.data;
		// this.badTagsString = this.badTags.toString();
		this.badTagsString = this.badTags.join(", ");
		// console.log(this.badTagsString);
	},

	methods: {
		async runUrl() {
			this.badTags = this.badTagsString.split(",").map((badTag) => {
				return badTag.trim();
			});

			// this.badTags = this.badTagsString.split(",").reduce((newArr, batTag) => {
			// 	if (batTag.article_id == item.article_id && batTag.tag == item.tag) {
			// 		console.log("batTag: ", batTag.add_tag);
			// 		console.log("item: ", item.add_tag);
			// 		batTag.add_tag = item.add_tag.toString();
			// 	}
			// 	newArr.push(batTag);
			// 	return newArr;
			// }, []);

			// if (this.url.text) {
			this.isBusy = true;
			this.spinner = true;
			const { data } = await TaxonomyService.runUrl({
				url: this.url.text,
				badTags: this.badTags,

				// keyword: this.keyword.text,
				language_code: this.url.language_code,
				location_code: this.url.location.code.toString(),
				location_name: this.url.location.name.toString(),
				device: this.url.device,
				// serp_top_n: this.filterInput.value,
				forceUpdate: this.url.forceUpdate,
			});

			if (data[0]) {
				delete data[0].body;
				this.items = data;
				this.columns = Object.keys(data[0]);
			}
			console.log("this.items: ", this.items);
			this.isBusy = false;
			this.spinner = false;

			return;
			// }
		},
	},
};
</script>

